export const floorsData = [
  {
    name: "Sixth",
    state: "SIXTH",
    isLet: true,
    sqft: "LET",
    hasTerrace: true,
    plans: [
      {
        name: "Floor plan",
        img: "sixth.png",
      },
      {
        name: "Space plan",
        img: "sixth-space.png",
        specs: [
          { text: "8 cellular meeting rooms" },
          { text: "2 open collaboration spaces" },
          { text: "2 focus rooms" },
          { text: "2 dining rooms" },
          { text: "1 ante-room to dining room" },
          { text: "total desks: 68", isTotal: true },
        ],
      },
    ],
    images: [
      {
        img: "./images/gallery/ter1.jpg",
        location: { x: 65.71, y: 18.4, z: 23.905 },
      },
      {
        img: [
          "./images/gallery/fit1.jpg",
          "./images/gallery/fit2.jpg",
          "./images/gallery/fit3.jpg",
        ],
        location: { x: 68.835, y: 18.4, z: 29.398 },
      },
    ],
  },
  {
    name: "Fifth",
    state: "FIFTH",
    isLet: true,
    sqft: "LET",
    hasTerrace: true,
    plans: [
      {
        name: "Floor plan",
        img: "fifth.png",
      },
      {
        name: "Space plan",
        img: "fifth-space.png",
        specs: [
          { text: "7 cellular meeting rooms" },
          { text: "5 Open collaboration spaces" },
          { text: "total desks: 68", isTotal: true },
        ],
      },
    ],
    images: [
      {
        link: "fifthFloorGallery",
        location: { x: 58.325, y: 17, z: 36.409 },
        scene: "floor5th",
      },
      {
        img: "./images/gallery/fif1.jpg",
        location: { x: 74.637, y: 17, z: 49.943 },
      },
      {
        img: "./images/gallery/fif2.jpg",
        location: { x: 66.528, y: 17, z: 28.665 },
      },
      {
        img: "./images/gallery/fif3.jpg",
        location: { x: 68.207, y: 17, z: 41.978 },
      },
      {
        img: "./images/gallery/fif4.jpg",
        location: { x: 77.934, y: 17, z: 61.741 },
      },
      {
        img: "./images/gallery/fif5.jpg",
        location: { x: 86.212, y: 17, z: 62.774 },
      },
      {
        img: "./images/gallery/ter5.jpg",
        location: { x: 71.123, y: 17, z: 16.739 },
      },
    ],
  },
  {
    name: "Fourth",
    state: "FOURTH",
    sqft: 7162,
    plans: [
      {
        name: "Floor plan",
        img: "fourth.png",
      },
      // {
      //   name: "Space plan 1:8",
      //   img: "fourth-1-8.png",
      //   specs: [
      //     { text: "2 offices" },
      //     { text: "5 cellular meeting rooms" },
      //     { text: "4 open collaboration spaces" },
      //     { text: "7 focus rooms" },
      //     { text: "2 touchdown spaces" },
      //     { text: "total desks: 158", isTotal: true },
      //   ],
      // },
      // {
      //   name: "Space plan 1:12",
      //   img: "fourth-1-12.png",
      //   specs: [
      //     { text: "4 offices" },
      //     { text: "7 cellular meeting rooms" },
      //     { text: "3 open collaboration spaces" },
      //     { text: "4 focus rooms" },
      //     { text: "3 touchdown spaces" },
      //     { text: "total desks: 97", isTotal: true },
      //   ],
      // },
    ],
    images: [
      {
        img: "./images/gallery/frh1.jpg",
        location: { x: 58.325, y: 14.4, z: 36.409 },
      },
      // {
      //   link: "fifthFloorGallery",
      //   location: { x: 58.325, y: 14.4, z: 36.409 },
      //   scene: "floor4th",
      // },
    ],
  },
  {
    name: "Third",
    state: "THIRD",
    sqft: 13628,
    isLet: true,
    sqft: "UNDER OFFER",
    hasTerrace: true,
    plans: [
      {
        name: "Floor plan",
        img: "third.png",
      },
    ],
    images: [
      {
        img: "./images/gallery/thr1.jpg",
        location: { x: 58.955, y: 10, z: 30.316 },
      },
      {
        img: "./images/gallery/ter4.jpg",
        location: { x: 80.025, y: 10, z: 27.927 },
      },
    ],
  },
  {
    name: "Second",
    state: "SECOND",
    sqft: 14139,
    plans: [
      {
        name: "Floor plan",
        img: "second.png",
      },
    ],
    images: [
      {
        img: "./images/gallery/sec1.jpg",
        location: { x: 72.635, y: 7.4, z: 21.395 },
      },
      {
        img: "./images/gallery/sec2.jpg",
        location: { x: 81.421, y: 7.4, z: 62.789 },
      },
      {
        img: "./images/gallery/sec3.jpg",
        location: { x: 59.311, y: 7.4, z: 33.165 },
      },
    ],
  },
  {
    name: "First",
    state: "FIRST",
    isLet: true,
    sqft: "LET",
    plans: [
      {
        name: "Floor plan",
        img: "first.png",
      },
    ],
    images: [],
  },
  {
    name: "Ground",
    state: "GROUND",
    sqft: 4054,
    plans: [
      {
        name: "Floor plan",
        img: "ground.png",
      },
      {
        name: "Space plan",
        img: "ground-spec.png",
        specs: [
          // { text: "2 offices" },
          // { text: "5 cellular meeting rooms" },
          // { text: "4 open collaboration spaces" },
          // { text: "7 focus rooms" },
          // { text: "2 touchdown spaces" },
          // { text: "total desks: 158", isTotal: true },
        ],
      },
    ],
    images: [
      // {
      // 	link: "groundfloor",
      // 	location: { x: 62.069, y: 0.5, z: 46.03 },
      // 	scene: null,
      // 	img: "reception",
      // },
      {
        img: [
          "./images/gallery/am1.jpg",
          "./images/gallery/rec1.jpg",
          "./images/gallery/rec3.jpg",
        ],
        location: { x: 62.069, y: 0.5, z: 46.03 },
      },
      {
        img: ["./images/gallery/am2.jpg"],
        location: { x: 67.285, y: 0.5, z: 55.651 },
      },
    ],
  },
  {
    name: "Lower Ground",
    state: "BASEMENT",
    sqft: null,
    plans: [
      {
        name: "Floor plan",
        img: "lowerground.png",
      },
    ],
    images: [
      {
        img: ["./images/gallery/sh1.jpg", "./images/gallery/sh3.jpg"],
        location: { x: 72.918, y: -6, z: 55.84 },
      },
      {
        img: "./images/gallery/sh2.jpg",
        location: { x: 85.103, y: -6, z: 57.368 },
      },
    ],
  },
];

floorsData.map((fl, i) => (fl.index = i));

export function GetFloor(name) {
  const result = floorsData.find(
    (fl) => fl.name.toLowerCase() === name.toLowerCase()
  );
  if (!result) {
    console.error("NO FLOOR FOUND WITH NAME " + name);
    return null;
  }
  return result;
}
